<template>
  <b-overlay
    :show="isLoading"
    :opacity="0.7"
    blur="4px"
  >
    <v-select
      v-model="user"
      :reduce="(item) => item.value"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="users.filter(u => !u.isMe)"
      label="text"
      :placeholder="placeholder"
      debounce="200"
      :clearable="false"
      :multiple="multiple"
      @search="getUser"
    >
      <template
        v-if="!multiple"
        #selected-option="{ avatar, text, isOnline }"
      >
        <div class="d-flex">
          <b-avatar
            :src="getAvatar(avatar)"
            size="30"
            badge
            :badge-variant="isOnline ? 'success' : 'secondary'"
            :text="text.charAt(0)"
            class="mr-1"
            variant="light-primary"
          />
          <span> {{ IS_MOBILE() ? FORMAT_NAME(text) : text }}</span>
        </div>
      </template>
      <template #option="{ avatar, text, isOnline, value }">
        <b-avatar
          :src="getAvatar(avatar)"
          size="30"
          badge
          :badge-variant="isOnline ? 'success' : 'secondary'"
          :text="text.charAt(0)"
          class="mr-1"
          :style="{color: isSelected(value) && 'black' }"
          :variant="isSelected(value) ? 'warning' : 'light-primary'"
        />
        <span> {{ IS_MOBILE() ? FORMAT_NAME(text) : text }}</span>
      </template>
    </v-select>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import useApollo from '@/plugins/graphql/useApollo'
import { BAvatar, BOverlay } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    vSelect,
    BAvatar,
    BOverlay,
  },
  props: {
    value: {
      type: [Object, Number, String, Array],
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select User',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: [Object, String],
      default: () => null,
    },
  },
  data() {
    return {
      users: [
        ...(getUserData().isAdmin ? [] : [{
          text: this.placeholder, value: null, isOnline: false, isMe: this.placeholder === 'Me',
        }]),
      ],
      self: getUserData(),
      isLoading: false,
    }
  },
  computed: {
    isMe() {
      return this.placeholder === 'Me'
    },
    user: {
      get() {
        return this.isLoading ? null : this.value
      },
      set(value) {
        // eslint-disable-next-line no-param-reassign
        if (Array.isArray(value) && !value.length) value = null
        if (this.multiple && value && value.length > 0) {
          if (value[value.length - 1] === null) {
            this.$emit('input', null)
            this.$forceUpdate()
          } else this.$emit('input', value.filter(val => val !== null))
          return
        }
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.getUser()
    if (!this.value && this.isMe) {
      this.$emit('input', getUserData().uuid)
    }
  },
  methods: {
    afterFetch(response) {
      const { data } = response.data.users
      const users = data.filter(user => user.value !== this.self.uuid)

      users.unshift(
        ...(data.filter(user => user.value === this.self.uuid)
          .map(user => ({
            ...user,
            text: 'Me',
          }))),
      )

      if (users.length === 1) {
        this.users = [...users]
        this.$nextTick(() => {
          if (!this.value) this.$emit('input', users[0].value)
        })
      } else {
        this.users = [
          ...(this.self.isAdmin ? [] : [{ text: this.placeholder, value: null, isMe: this.isMe }]),
          ...users,
        ]
        if (!users.find(user => user.value === this.user)) {
          let userUUid = users?.[0]?.value
          if (this.multiple) {
            userUUid = null
          }
          if (this.self.isAdmin || !this.value) this.user = userUUid
        }
      }
    },
    getUserByCompany(keyword) {
      useApollo.users.searchUserByCompany({ keyword, companyUid: this.companyId }).then(response => {
        this.afterFetch(response)
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getUser(keyword = '', loading = false) {
      if (this.companyId) {
        this.getUserByCompany(keyword)
        return
      }
      this.isLoading = true
      useApollo.users.searchUser({
        keyword,
        projectId: this.$store.state.project.selectedProject,
      }).then(response => {
        this.afterFetch(response)
      }).finally(() => {
        if (typeof loading === 'function') loading(false)
        this.isLoading = false
      })
    },
    isSelected(item) {
      return (this.value === item) || (Array.isArray(this.value) && this.value.includes(item))
    },
  },
}
</script>
