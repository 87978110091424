<template>
  <div>
    <b-card
      no-body
      class="px-2"
    >
      <b-row class="align-items-baseline">
        <b-col>
          <b-card-text
            style="margin-top: 10px"
            class="text-bold"
          >
            <feather-icon
              icon="ClockIcon"
              size="20"
            />  {{ $t('Timeline') }}
          </b-card-text>
        </b-col>
        <b-col md="10">
          <Filters
            v-model="form"
            date-type="date"
            :multiple-user-select="true"
            :user-placeholder="$t('All Users')"
            @timezone-changed="updateTimezone"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-tabs
      id="tour-weekly-time"
      pills
    >
      <b-tab :title="$t('Week')">
        <b-overlay :show="showOverlay">
          <week-table
            :rows="rows"
            :search-date="todayDate"
            :format-time="formatTime"
            :search-term="users"
            :selected-timezone="selectedTimezone"
            @toogleOverlay="(value)=> showOverlay = value"
          />
        </b-overlay>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BCardText, BRow, BCol, BOverlay, BTab, BTabs, BCard,
} from 'bootstrap-vue'
import Filters from '@/views/common/components/Filter.vue'
import WeekTable from './components/week.vue'

const moment = require('moment')

export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BOverlay,
    BCard,
    BTab,
    BTabs,
    WeekTable,
    Filters,
  },
  data() {
    const today = this.TODAY({ localTime: false })
    return {
      searchTerm: null,
      rows: [],
      searchDate: this.TODAY({ localTime: false }),
      showOverlay: false,
      paginationData: {},
      selectedTimezone: localStorage.timezone ?? 'Asia/Kathmandu',
      timezones: [
        { value: null, text: 'Any' },
        { value: 'Asia/Kathmandu', text: 'Asia/Kathmandu' },
        { value: 'Asia/Seoul', text: 'South Korea/Seoul' },
      ],
      form: {
        date: this.TODAY({ localTime: false }),
        start_date: this.TODAY({ localTime: false }),
        end_date: this.TODAY({ localTime: false }),
        month_start: moment().format('YYYY-MM'),
        month_end: moment().format('YYYY-MM'),
        userId: this.defaultUser,
        time_zone: localStorage.timezone ?? 'Asia/Kathmandu',
      },
      today,
    }
  },
  computed: {
    todayDate() {
      return `${this.form.date} 00:00:00`
    },
    users() {
      return this.form.userId
    },
  },
  methods: {
    formatTime(value) {
      const sec = parseInt(value, 10) // convert value to number if it's string
      if (sec < 0) return '0m 0s'
      const hours = Math.floor(sec / 3600) // get hours
      let minutes = Math.floor((sec - (hours * 3600)) / 60) // get minutes
      // add 0 if value < 10; Example: 2 => 02
      if (minutes < 10) { minutes = `0${minutes}` }
      return `${hours}h ${minutes}m` // Return is HH : MM : SS
    },
    updateTimezone() {
      if (this.isAfterTODAY(this.form.date)) this.form.date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.start_date)) this.form.start_date = this.TODAY({ localTime: false })
      if (this.isAfterTODAY(this.form.end_date)) this.form.end_date = this.TODAY({ localTime: false })
    },
  },
}
</script>
<style>
.avatar-status-indicator{
  border-radius: 50%; width: 12px; height: 12px; position: absolute; right: 0; bottom: 0;
}
.avatar-online {
  background: #28c76f;
}
.avatar-offline {
  background: #82868b;
}
.nickname{
  font-size:14px;
  font-style:italic;
  margin-top:-16px;
}
</style>
